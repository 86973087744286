.rbc-calendar {
  position: relative;
  top: -46px;
}
.rbc-toolbar {
  width: 20%;
  justify-content: flex-start;
  position: relative;
  top: -30px;
}
.rbc-header {
  background-color: #3398fc;
  color: #FFF;
  height: 60px;
  display: flex;
  align-items: center;
  border-left: none!important;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.72px;
}
.rbc-day-bg {
  border-left: none!important;
}
.rbc-date-cell {
  text-align: left;
  padding-right: unset;
  padding-left: 5px;
  font-size: 20px;
  font-weight: 600;
  color: #6C7293;
}
.rbc-event {
  background-color: unset!important;
  color: #404040;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.56px
}
.rbc-off-range {
  opacity: 0.4;
}
.rbc-off-range-bg {
  background-color: unset!important;
}
